let CSRF_TOKEN = '';
let select2DefaultOptions = {
	minimumResultsForSearch: -1,
	width: '100%'
};

/**
 * The main class of the JavaScript.
 *
 * @constructor
 */
function Main() {

	/**
	 * @type {Main} The instance of this class.
	 */
	let self = this;


	/**
	 * The Main constructor.
	 */
	this.init = function() {

		$('input[type="checkbox"]').dcCheckbox();
		$('input[type="radio"]').dcRadioButton();

		$('select').not('.select2-hidden-accessible').select2(select2DefaultOptions);

		this.registerOnClick('[data-delete-software]', self.deleteSoftware);
		this.registerOnClick('#softwareTestRegex', self.softwareTestRegex);

		this.filterBox();
	};

	/**
	 * Register for an click event with a event handler.
	 *
	 * @param {string} selector The selector.
	 * @param {object} callback The callback.
	 */
	this.registerOnClick = function(selector, callback) {
		$(document).on('click', selector, callback);
	};

	/**
	 * Toggles the filter box arrow.
	 */
	this.filterBox = function() {

		let filterBoxInner = $('#filterBoxInner');
		let filterBoxToggle = $('.filter-box-toggle');

		filterBoxInner.on('shown.bs.collapse', function() {
			let selects = $(this).find('select');
			selects.select2('destroy');
			selects.not('.select2-hidden-accessible').select2(select2DefaultOptions);
		});

		filterBoxInner.on('show.bs.collapse', function() {
			filterBoxToggle.find('i.fa').removeClass('fa-caret-down').addClass('fa-caret-up');
		});
		filterBoxInner.on('hide.bs.collapse', function() {
			filterBoxToggle.find('i.fa').removeClass('fa-caret-up').addClass('fa-caret-down');
		});
	};

	/**
	 * Deletes the software row.
	 */
	this.deleteSoftware = function() {

		let obj = $(this);
		let id = parseInt(obj.data('delete-software'));

		if(confirm(tex('software.delete')) === true) {

			$.ajax({
				method: 'POST',
				url: '/ajax/delete-software',
				data: { id: id, csrf_token: CSRF_TOKEN }
			})
			.done(function(obj) {

				if(_.isObject(obj) === true) {
					if(_.has(obj, 'status') === true) {
						if(_.has(obj, 'id') === true) {
							$('[data-software="' + obj.id + '"]').remove();
						}
						else {
							alert(tex('global.error') + ': ' + tex('error.internal_error'));
						}
					}
					else {
						alert(tex('global.error') + ': ' + tex('error.internal_error'));
					}
				}
				else {
					alert(tex('global.error') + ': ' + tex('error.internal_error'));
				}
			})
			.fail(function() {
				alert(tex('global.error') + ': ' + tex('error.internal_error'));
			});
		}
	};

	/**
	 * Checks the software regex.
	 */
	this.softwareTestRegex = function() {

		let regexObj = $('#softwareRegex');
		if(regexObj.val() !== '') {

			$.ajax({
				method: 'POST',
				url: '/ajax/software-check-regex',
				data: { regex: regexObj.val(), csrf_token: CSRF_TOKEN }
			})
			.done(function(obj) {

				if (_.isObject(obj) === true) {
					if (_.has(obj, 'status') === true) {

						let resutlObj = $('#softwareTestRegexResult');
						resutlObj.removeClass('error').removeClass('success');
						if (obj.status === 1) {
							resutlObj.addClass('success').html('<i class="fa fa-check"></i> ' + tex('software.regex.test.success'));
						}
						else {
							resutlObj.addClass('error').html('<i class="fa fa-times"></i> ' + tex('software.regex.test.error'));
						}
					}
					else {
						alert(tex('global.error') + ': ' + tex('error.internal_error'));
					}
				}
				else {
					alert(tex('global.error') + ': ' + tex('error.internal_error'));
				}
			})
			.fail(function() {
				alert(tex('global.error') + ': ' + tex('error.internal_error'));
			});
		}
	};


	// Call the class constructor
	this.init();
}


$(document).ready(function() {
	CSRF_TOKEN = $('input[name="csrf_token"]').val();
	new Main();
});